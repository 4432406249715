import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { Observable, from } from 'rxjs';
import { Injectable, NgZone, TemplateRef, ViewChild } from '@angular/core';
import { User } from "../services/user";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";



@Injectable({
  providedIn: 'root'
})


export class AuthService {
  public userData: any; // Save logged in user data
  error: string;
  alert: string;

  public subUser: any;


  notificationModal: BsModalRef;
  notification = {
    keyboard: true,
    class: "modal-dialog-centered modal-danger"
  };

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,  
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private modalService: BsModalService,


  ) {    
    
    console.log("%c  Admin+ v7.1.0", 'color: #a9ff01');
    

    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */    
    this.subUser = this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        
        if (typeof window !== 'undefined') {
          localStorage.setItem('user', JSON.stringify(this.userData));
          JSON.parse(localStorage.getItem('user'));

        }

      }


    })

    
  }

  openNotificationModal(msg, modalNotification: TemplateRef<any>) {

  this.error = msg;
  
    this.notificationModal = this.modalService.show(
      modalNotification,
      this.notification
    );
  }

  SignInDemo() {

    if (typeof window !== 'undefined') {
      localStorage.clear();
    }

    return this.afAuth.auth.signInWithEmailAndPassword("demo@espiao.com.br", "123456")
      .then((result) => {
        this.getUser(result.user);
        this.ngZone.run(() => {
          console.log("%c  SignIn: " + "OK", 'color: #ff0000');
          this.goDashboard();
        });
      }).catch((error) => {
        
      })
  }

  // Sign in with email/password
  SignIn(email, password, modal) {

    if (typeof window !== 'undefined') {
      localStorage.clear();
    }
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.getUser(result.user);
        this.ngZone.run(() => {
          console.log("%c  SignIn: " + "OK", 'color: #ff0000');
          this.goDashboard();
        });
      }).catch((error) => {
        this.openNotificationModal(error.message, modal)
      })
  }
  

  // Sign up with email/password
  SignUp(email, password, modal) {

    if (typeof window !== 'undefined') {
      localStorage.clear();
    }


    return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign 
        up and returns promise */
        this.getUser(result.user);
        console.log("%c  SignUp: " + "OK", 'color: #ff0000');
        this.goDashboard();
      }).catch((error) => {
        this.openNotificationModal(error.message, modal)
      })


      

  }





  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification()
    .then(() => {
      this.router.navigate(['verify-email-address']);
    })
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail, modal) {
    return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail)
    .then(() => {
      this.alert = 'E-mail de redefinição de senha enviado, verifique sua caixa de entrada.';
      
    }).catch((error) => {
      this.openNotificationModal(error, modal)
    })
  }


  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    if (typeof window !== 'undefined') {
      const user = JSON.parse(localStorage.getItem('user'));
      return (user !== null) ? true : false;
      }

  }

    // Returns true when user is looged in and email is verified
    get uid(): string {
      if (typeof window !== 'undefined') {
        const user = JSON.parse(localStorage.getItem('user'));
        return (user !== null) ? user.uid : null;
        }

    }

    

    



  // Sign in with Google
  GoogleAuth() {
    if (typeof window !== 'undefined') {
      localStorage.clear();
    }
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }

    // Sign in with Google
    FacebookAuth() {
      if (typeof window !== 'undefined') {
        localStorage.clear();
      }

      return this.AuthLogin(new auth.FacebookAuthProvider());
    }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
    .then((result) => {      
      this.getUser(result.user);
      this.ngZone.run(() => {
        console.log("%c  AuthLogin: " + "OK", 'color: #ff0000');
        this.goDashboard();
      });
    }).catch((error) => {
      this.alert = error;
    })
  }

  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  getUser(user: any) {
    const userRef: AngularFirestoreDocument<any> =  this.afs.collection('users').doc(user.uid).collection('admin').doc('profile');


    userRef.get()
    .forEach((doc) => {

      if (!doc.exists) {
        this.setUser(user)
        console.log("%c  not exist", 'color: #ff0000');
      } else {
        console.log("%c  exist", 'color: #ff0000');

        this.userData = {
          
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
          emailVerified: user.emailVerified,
          add: user.add,
          app: user.app,
          plan: user.plan,
          active: user.active,
          dropsPhotos: user.dropsPhotos,
          dropsAudios: user.dropsAudios,
          created: user.created,
          expire: user.expire
        }

        if (user) {
          this.userData = user;
          if (typeof window !== 'undefined') {
            localStorage.setItem('user', JSON.stringify(this.userData));
            JSON.parse(localStorage.getItem('user'));
            }
        
 
        } else {
          if (typeof window !== 'undefined') {
            localStorage.setItem('user', null);
            JSON.parse(localStorage.getItem('user'));
            }
        
  
        }

      }
    });
    
  }
  

setUser(user: any) {

  
   let created =  Math.floor(Date.now());
   let expire = created + 172800000;//<--2 dias

   console.log("%c  created: " + created, 'color: #ff0000');
   console.log("%c  expire: " + expire, 'color: #ff0000');

   const userRef: AngularFirestoreDocument<any> =  this.afs.collection('users').doc(user.uid).collection('admin').doc('profile');

    this.userData = {
    uid: user.uid,
    email: user.email,
    displayName: user.displayName,
    photoURL: user.photoURL,
    emailVerified: user.emailVerified,
    add: false,
    app: 'Admin+',
    plan: 'Free',
    active: true,
    dropsPhotos: 0,
    dropsAudios: 0,
    created: created,
    expire: expire //<--2 dias
  }

  if (typeof window !== 'undefined') {
    localStorage.setItem('user', JSON.stringify(this.userData));
    JSON.parse(localStorage.getItem('user'));
    }


  
  return userRef.set(this.userData, {
    merge: true
  })
}

  goDashboard() {
    
    this.router.navigate(['dashboard']);

    setTimeout(() => {
      this.router.navigate(['dashboard']);
    }, 1000);

  }



  // Sign out 
  SignOut() {
    this.subUser.unsubscribe();
    if (typeof window !== 'undefined') {
      localStorage.clear();
    }
    return this.afAuth.auth.signOut().then(() => { 
         // remove user from local storage to log user out
      this.router.navigate(['login']);
    })
  }

    // Sign out Demo 
    SignOutDemo() {
      this.subUser.unsubscribe();
      if (typeof window !== 'undefined') {
        localStorage.clear();
      }
      return this.afAuth.auth.signOut().then(() => { 
           // remove user from local storage to log user out
        this.router.navigate(['signup']);
      })
    }





  
  

}