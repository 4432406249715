import { Component, OnInit, ElementRef, TemplateRef, HostListener, Injectable, AfterViewInit, OnDestroy } from "@angular/core";
import { ROUTES } from "../sidebar/sidebar.component";
import { DataService } from '../../shared/services/data.service';
import { AdminService } from '../../shared/services/admin.service';
import { ConfigService } from '../../shared/services/config.service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { AuthService } from "../../shared/services/auth.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { HttpClientModule } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { timer } from 'rxjs';
import swal from "sweetalert2";


declare function showChatApinc(uid, email, created_at) : any;
declare function showChatSyncsoft(uid, email, created_at) : any;
declare function showChatPaiGuardiao() : any;
declare function iniDateDropper() : any;



import {
  Location,
  LocationStrategy,
  PathLocationStrategy
} from "@angular/common";




@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})

@Injectable({
  providedIn: 'root'
})
export class NavbarComponent implements OnInit, OnDestroy {



  public focus;
  public listTitles: any[];
  public location: Location;
  sidenavOpen: boolean = false;
  isUrl: boolean = false;
  isMobileResolution: boolean;

  dismissible = true;


  code: string;



  public isTimer: Boolean = false;
  
  

  notificationModal: BsModalRef;
  notification = {
    keyboard: true,
    class: "modal-dialog-centered modal-sm"
  };






  data: any

  constructor(
    public configService: ConfigService,
      public dataService: DataService,
      public adminService: AdminService,
    location: Location,
    private element: ElementRef,
    private router: Router,
    public modalService: BsModalService,
       private http: HttpClient,
    ) {

       
    this.location = location;
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
        this.dataService.isLoading = "load";

      }
      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        this.dataService.isLoading = "none";

        if (window.innerWidth < 1200 ) {
          document.body.classList.remove("g-sidenav-pinned");
          document.body.classList.add("g-sidenav-hidden");
          this.sidenavOpen = false;
        }
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator
        this.dataService.isLoading = "none";

        // Present error to user
        console.log(event.error);
      }
    });

    if (window.innerWidth < 1200 ) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }

    this.refrshTimer()
  }

  @HostListener("window:resize", ["$event"])
  isMobile(event) {
    if (window.innerWidth < 1200 ) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }


interval;

startTimer() {
  
  this.interval = setInterval(() => {
    console.log("startTimer");
    if(this.dataService.statusAdd === 'online' ){
      
      if (typeof window !== 'undefined') {
        localStorage.setItem('devicesSelected', this.dataService.didAdd);


      }
      this.updateSelected(this.dataService.didAdd);
      this.dataService.isAdd = false;
      this.notificationModal.hide();
      clearInterval(this.interval);
    }
  },1000,1000)
}


refrshTimer() {
  
  this.interval = setInterval(() => {

    if (this.dataService.devicesList.length > 0) {
      this.dataService.refresh();
    }

    
  },10000,10000)
}




  ngOnInit() {

    if(this.configService.demo){
      this.configService.callDemo()
    }

  }




  bsValue = new Date();
  bsRangeValue: Date[];
  maxDate = new Date();




  doesFileExist(urlToFile) {
    var xhr = new XMLHttpRequest();
    xhr.open('HEAD', urlToFile, false);
    xhr.send();

    if (xhr.status == 404) {
      return false;
    } else {
      return true;
    }
  }

  
  closeChat(){
    document.getElementById("chatbar").classList.add("is-closed");
    if(this.isMobileResolution == true){
      document.getElementById("contactsbar").classList.remove("is-closed");
    }
    this.dataService.windowChat = false;


    var chatWidget = document.getElementsByClassName("__jivoMobileButton")[0]
    if (chatWidget) {
      chatWidget.classList.remove("d-none");
    } 




  }




  openNotificationModal(modalNotification: TemplateRef<any>) {
  
    this.dataService.isThis = true
  
    if (this.configService.demo || this.adminService.plan !== "Pro") {
      this.router.navigate(['plans']);
    }else{
  
      this.code = this.dataService.shareCode( this.adminService.uid );
    
      this.notificationModal = this.modalService.show(
        modalNotification,
        this.notification
      );
    
        this.startTimer();
    }
  
   
    
  
  }



ngAfterViewInit() {

  

  this.listTitles = ROUTES.filter(listTitle => listTitle);



  if (!this.configService.demo) {
    
  }

  

  

  iniDateDropper();

}


ngOnDestroy() {
  this.dataService.closeSearch()
  document.body.classList.remove("g-sidenav-pinned");
  document.body.classList.remove("g-sidenav-show");
  this.sidenavOpen = false;
  console.log("%c  stop nav", 'color: #ff0000');



}




  getTitle() {

    
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(1);
    }

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return "Dashboard";
  }






  openSidebar() {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.sidenavOpen = false;
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      this.sidenavOpen = true;
    }
  }
  
  toggleSidenav() {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.sidenavOpen = false;
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      this.sidenavOpen = true;
    }
  }



  redirectTo(uri:string){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
   }

  
   autoredirectTo(uri:string){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
   }


   notList(){

    return this.router.url == '/dashboard' || this.router.url == '/location' || this.router.url == '/settings' || this.router.url == '/plans';

   }

   

   isDashboard(){

    return this.router.url == '/dashboard';

   }




    showNotification(){
      swal.fire({
          title: 'Oops!',
          text: 'No momento voce nao possui notificações',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-info'
      })
    }
 

   showNotificationAcc33(){
    swal.fire(
      
      'Oops!',
      'Wi-Fi não esta ativado no<br>  <b>Dispositivo Alvo</b>, ' +
      '<a target="_blank", href="https://intercom.help/syncsoft-hc/pt-BR/articles/8400382-como-ativar-acessibilidade-pro">Clique Aqui</a> ' +
      'para ver como ativar',
      'warning',
    )
  }

  showNotificationAcc(){
    swal.fire(
      
      'Oops!',
      'Wi-Fi não esta ativado no<br>  <b>Dispositivo Alvo</b>, ' +
      '<a target="_blank", href="https://intercom.help/syncsoft-hc/pt-BR/articles/8400382-como-ativar-acessibilidade-pro">Clique Aqui</a> ' +
      'para ver como ativar',
      'warning',
    )
  }

  showNotificationDemo(){
    swal.fire({
        title: 'Oops!',
        text: 'Não é possível Adicionar novo dispositivo na versão Demo',
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-info'
    })
  }

  closeNotification(){
    this.notificationModal.hide()
    clearInterval(this.interval);
  }


  cupom() {
    this.configService.openUrl(this.configService.cupom);
  }

  updateSelected(id: string) {


    if (this.dataService.id == id ){
      this.dataService.closeSearch();
    }else{

      if (typeof window !== 'undefined') {
        localStorage.setItem('devicesSelected', id);


      }
      this.dataService.setDevice(id);
      this.dataService.windowChat = false;
      this.dataService.nameChat = '';
      this.dataService.closeSearch();
      
      
      
      setTimeout(() => 
      {
        //this.dataService.loadDevice() 
      },
      1500);
     
  }
    




  }

  buy() {
    this.configService.openUrl(this.configService.buynow);
}

  isDashboardDemo(): boolean {
    if (window.location.href.indexOf("dashboard") !== -1 && this.adminService.plan === 'Free' && this.dataService.devicesList.length > 0) {
        return true;
    } else {
        return false;
    }
}


  



}





