<nav id="navbar-main" class="navbar navbar-horizontal  navbar-main navbar-expand-xl navbar-light fixed-top">
    <div class="container-fluid">
        <a class="navbar-brand d-none d-xl-block" href="https://{{configService.domain}}" target="_blank">
            <img style="height:32px" src="{{configService.darklogo}}" />
        </a>

        <a class="navbar-brand d-xs-block">

        </a>
        



        <a class="navbar-toggler" data-toggle="collapse" data-target="#navbar-collapse" aria-expanded="false" aria-label="Toggle navigation" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic">
            <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
            </div>
        </a>


        <div class="navbar-collapse navbar-custom-collapse collapse" id="collapseBasic" [collapse]="isCollapsed">
            <div class="navbar-collapse-header">
                <div class="row">
                    <div class="col-6 collapse-brand">
                        <a href="https://{{configService.domain}}" target="_blank">
                            <img src="{{configService.darklogo}}" />
                        </a>
                    </div>
                    <div class="col-6 collapse-close">
                        <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbar-collapse" aria-controls="navbar-collapse" aria-expanded="false" aria-label="Toggle navigation" (click)="isCollapsed = !isCollapsed">
              <span></span> <span></span>
            </button>
                    </div>
                </div>
            </div>




            <ul class="navbar-nav mr-auto d-lg-none">
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" href="https://{{configService.domain}}/#funcionalidades" target="_blank" (click)="isCollapsed = !isCollapsed">
                        <span class="nav-link-inner--text">Funcionalidades</span>
                    </a>
                </li>
                <li class="nav-item  d-block d-md-none">
                    <a class="nav-link" routerLinkActive="active" [routerLink]="['/signup']" (click)="isCollapsed = !isCollapsed">
                        <span class="nav-link-inner--text">Criar conta</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" [routerLink]="['/login']" (click)="isCollapsed = !isCollapsed">
                        <span class="nav-link-inner--text">Entrar</span>
                    </a>
                </li>
            </ul>
            <hr class="d-lg-none" />
            <ul class="d-lg-none navbar-nav align-items-lg-center ml-lg-auto">
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" href="{{configService.install}}" target="_blank" (click)="isCollapsed = !isCollapsed">
                        <span class="nav-link-inner--text">Guia de instalação</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" href="https://{{configService.domain}}/comprar.html" target="_blank" (click)="isCollapsed = !isCollapsed">
                        <span class="nav-link-inner--text">Comprar</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>

<div [ngClass]="{ 'row': isMobileResolution === false}">
    <div [ngClass]="{ 'col-12 col-lg-5 col-lg-5': isMobileResolution === false}" style="z-index:1050">
        <router-outlet></router-outlet>
    </div>
    <div class="col-7 bg-info d-none d-xl-block">
        <div class="login-hero">
            <ul class="circles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div>
    </div>
  </div>






