import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";

// Import canActivate guard services
import { AuthGuard } from "./shared/guard/auth.guard";
import { SecureInnerPagesGuard } from "./shared/guard/secure-inner-pages.guard";


const routes: Routes = [
  {
    path: "#login",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "#signup",
    redirectTo: "signup",
    pathMatch: "full",
  },
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'location',
        loadChildren: () => import('./pages/location/location.module').then(m => m.LocationModule)
      },
      {
        path: 'timeline',
        loadChildren: () => import('./pages/timeline/timeline.module').then(m => m.TimelineModule)
      },
      {
        path: 'keys',
        loadChildren: () => import('./pages/keys/keys.module').then(m => m.KeysModule)
      },
      {
        path: 'websites',
        loadChildren: () => import('./pages/websites/websites.module').then(m => m.WebsitesModule)
      },
      {
        path: 'searches',
        loadChildren: () => import('./pages/searches/searches.module').then(m => m.SearchesModule)
      },
      {
        path: 'calls',
        loadChildren: () => import('./pages/calls/calls.module').then(m => m.CallsModule)
      },
      {
        path: 'audios',
        loadChildren: () => import('./pages/audios/audios.module').then(m => m.AudiosModule)
      },
      {
        path: 'photos',
        loadChildren: () => import('./pages/photos/photos.module').then(m => m.PhotosModule)
      },
      {
        path: 'contacts',
        loadChildren: () => import('./pages/contacts/contacts.module').then(m => m.ContactsModule)
      },
      {
        path: 'sms',
        loadChildren: () => import('./pages/sms/sms.module').then(m => m.SmsModule)
      },
      {
        path: 'whatsapp',
        loadChildren: () => import('./pages/whatsapp/whatsapp.module').then(m => m.WhatsAppModule)
      },
      {
        path: 'instagram',
        loadChildren: () => import('./pages/instagram/instagram.module').then(m => m.InstagramModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: 'installs',
        loadChildren: () => import('./pages/installs/installs.module').then(m => m.InstallsModule)
      },
      {
        path: 'plans',
        loadChildren: () => import('./pages/plans/plans.module').then(m => m.PlansModule)
      }
    ]
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
         path: '',
         loadChildren: () => import('./layouts/auth-layout/auth-layout.module').then(m => m.AuthLayoutModule)
      },
      { 
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
      },
      { 
        path: 'signup',
        loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupModule)
      },
      { 
        path: 'start',
        loadChildren: () => import('./pages/start/start.module').then(m => m.StartModule)
      }
    ]
  },
  {
    path: "**",
    redirectTo: "dashboard"
  }
];

@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}




