import { Component } from "@angular/core";
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {



//public loading: boolean;
    
constructor(
    private router: Router
    ) {


    


     this.router.events.subscribe((event: Event) => {
         if (event instanceof NavigationStart) {
            
             // Show loading indicator
             //this.loading = true;
             if (typeof window !== 'undefined') {

             window.scrollTo(0,0);
             }
         }

         if (event instanceof NavigationEnd) {
             // Hide loading indicator
             //this.loading = false;
             
         }

         if (event instanceof NavigationError) {
             // Hide loading indicator
             //this.loading = false;
             // Present error to user
             console.log(event.error);
            
         }
     });

     
}



}
