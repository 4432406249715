import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { Router } from "@angular/router";
import { DataService } from '../../shared/services/data.service';
import { AdminService } from '../../shared/services/admin.service';
import { ConfigService } from '../../shared/services/config.service';


@Component({
  selector: "app-auth-layout",
  templateUrl: "./auth-layout.component.html",
  styleUrls: ["./auth-layout.component.scss"]
})
export class AuthLayoutComponent implements OnInit, OnDestroy {
  test: Date = new Date();
  public isCollapsed = true;
  isMobileResolution: boolean;

  constructor(
    public configService: ConfigService,
    private router: Router,
       )
    {

  if (window.innerWidth < 1200 ) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }

  }

  @HostListener("window:resize", ["$event"])
  isMobile(event) {
    if (window.innerWidth < 1200 ) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }

  ngOnInit() {
    var html = document.getElementsByTagName("html")[0];
    // html.classList.add("auth-layout");
    var body = document.getElementsByTagName("body")[0];
    if (this.isMobileResolution) {
      body.classList.add("bg-white");
    } else {
      body.classList.add("bg-white");
    }
    var navbar = document.getElementsByClassName("navbar-horizontal")[0];
    navbar.classList.add("navbar-light");
  }
  ngOnDestroy() {
    var html = document.getElementsByTagName("html")[0];
    // html.classList.remove("auth-layout");
    var body = document.getElementsByTagName("body")[0];

    if (this.isMobileResolution) {
      body.classList.remove("bg-white");
    } else {
      body.classList.remove("bg-white");
    }

    var navbar = document.getElementsByClassName("navbar-horizontal")[0];
    navbar.classList.remove("navbar-light");
  }
}
