<nav [ngClass]="{ 'fix-faded': sidenavOpen && isMobileResolution }" class="navbar navbar-top fixed-top navbar-expand navbar-light faded-def" id="navbar-main">



    <div class="container-fluid">
        <div class="collapse navbar-collapse" id="navbarSupportedContent">

            <form [ngClass]="{ isshow: dataService.searchType == 1 && dataService.isSearchOpen }" class="navbar-search navbar-search-light form-inline" id="navbar-devices" style="display: none">
                <div class="form-group mb-0" [ngClass]="{ focused: focus === true }">
                    <div class="input-group input-group-alternative input-group-merge center">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <img height="16px" src="/assets/img/icons/common/smartphone.svg">
                            </span>
                        </div>
                        <div *ngFor="let device of dataService.devicesList; let i = index" class="container-devices">
                            <a [ngStyle]="{background: configService.color}" href="javascript:void(0);" class='cta-default' (click)="updateSelected(device.id)" data-action="search-close">
                                <h2 style="color: #fff; text-transform: uppercase; margin-top: 9px">
                                    {{ device.name.substring(0, 1) }}
                                </h2>
                            </a>
                        </div>
                        <div class='container-devices'>
                            <a href="javascript:void(0);" class='cta-default' (click)="openNotificationModal(modalNotification)">
                                <img height="16px" src="/assets/img/icons/common/plus.svg">
                            </a>
                        </div>
                    </div>
                </div>
                <button type="button" class="close" (click)="dataService.closeSearch()" data-action="search-close" data-target="#navbar-devices" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
            </form>

            <div [ngClass]="{ isshow: dataService.searchType == 2 && dataService.isSearchOpen}" *ngIf="dataService.devicesList.length > 0" class="navbar-search navbar-search-light form-inline" id="navbar-filter" style="display: none">

                <div class="form-group mb-0" [ngClass]="{ focused: focus === true }">
                    <div class="input-group input-group-muted input-group-merge center ">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <img height="16px" src="/assets/img/icons/common/search.svg">
                            </span>
                        </div>
                        <input id="search" [ngModel]="dataService.search" (ngModelChange)="dataService.modelSearch($event)" (click)="dataService.setDate($event.target.value)" class="form-control" autocomplete="off" placeholder="Pesquisar" type="text" (focus)="focus = true" (blur)="focus = false"
                        />
                    </div>
                </div>

                <button type="button" class="close" (click)="dataService.closeSearch()" data-action="search-close" data-target="#navbar-filter" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
            </div>

            <ul class="navbar-nav align-items-center mr-md-auto">
                <li class="nav-item d-xl-none">
                    <!-- Sidenav toggler -->
                    <div [ngClass]="dataService.windowChat === true ? 'd-none' : 'isshowAnimatedFast'" class="pr-3 sidenav-toggler sidenav-toggler-dark" data-action="sidenav-pin" data-target="#sidenav-main" (click)="openSidebar()">
                        <div class="sidenav-toggler-inner">
                            <i class="sidenav-toggler-line"></i>
                            <i class="sidenav-toggler-line"></i>
                            <i class="sidenav-toggler-line"></i>
                        </div>
                    </div>


                    <div [ngClass]="dataService.windowChat === true && dataService.isSearchOpen === false ? 'isshowAnimatedFast' : 'd-none'" (click)="closeChat()">

                        <div class="ml-0 row align-items-center">

                            <div class="" style="width: 20px;">
                                <img height="18px" src="/assets/img/icons/common/back.svg">
                            </div>


                            <div class="">
                                <h4 class="mb-0">{{ dataService.nameChat }}</h4>
                            </div>
                        </div>


                    </div>



                </li>
            </ul>







            <ul class="navbar-nav align-items-center ml-auto ml-md-0">
         
                <li [ngClass]="{ ishide: notList() }" currentUrl class='container-nav'>
                    <a href="javascript:void(0);" class='cta-default' (click)="dataService.openSearch(2)">
                        <img height="17px" src="/assets/img/icons/common/search.svg">
                    </a>
                </li>
                <li [ngClass]="{ ishide:  notList() }" currentUrl class='container-nav'>
                    <a href="javascript:void(0);" class='cta-default'>
                        <img height="17px" src="/assets/img/icons/common/clock.svg">
                    </a>
                </li>
                <li class="container-nav" *ngIf="dataService.status === 'online' && dataService.acc === false && dataService.sdk >= 33">
                    <a href="javascript:void(0);" class='cta-animated' (click)="showNotificationAcc33()">
                        <div class='notification' >
                            <div class='notify'>
                                <!--         <div class='sub-notify'></div> -->
                            </div>
                            <p>3</p>
                        </div>
                        <img height="17px" src="/assets/img/icons/common/bell.svg">
                    </a>
                </li>

 
                <li class="container-nav" *ngIf="dataService.status === 'online' && dataService.acc === false  && dataService.sdk <= 32">
                    <a href="javascript:void(0);" class='cta-animated' (click)="showNotificationAcc()">
                        <div class='notification' >
                            <div class='notify'>
                                <!--         <div class='sub-notify'></div> -->
                            </div>
                        </div>
                        <img height="17px" src="/assets/img/icons/common/bell.svg">
                    </a>
                </li>

                 <li class="container-nav" *ngIf="dataService.status === 'online' && dataService.acc === true">
                    <a href="javascript:void(0);" class='cta-default' (click)="showNotification()">
                        <div class='notification' >
                            <div class='notify'>
                                <!--         <div class='sub-notify'></div> -->
                            </div>
                        </div>
                        <img height="17px" src="/assets/img/icons/common/bell.svg">
                    </a>
                </li>


                <li class='container-nav pl-3' *ngIf="dataService.devicesCount < 1">
                    <a href="javascript:void(0);" class='cta-animated item-shadow bg-white' (click)="openNotificationModal(modalNotification)">
                        <div class='notification'>
                            <div class='notify'>
                                <!--         <div class='sub-notify'></div> -->
                            </div>
                        </div>
                        <img height="16px" src="/assets/img/icons/common/plus.svg">
                    </a>
                </li>

                <li class="container-nav pl-3" *ngIf="dataService.devicesCount > 0">
                    <a [ngStyle]="{background: configService.color}" href="javascript:void(0);" class='cta-default item-shadow' (click)="dataService.openSearch(1)">
                        <h2 *ngIf="dataService.name" style="color: #fff; text-transform: uppercase; margin-top: 9px">
                            {{ dataService.name.substring(0, 1) }}
                        </h2>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>


<div *ngIf="sidenavOpen === true" class="backdrop d-xl-none" (click)="toggleSidenav()"></div>
<div *ngIf="sidenavOpen === true" class="block-menu  d-xl-none"></div>


<input [ngClass]="{ ishide: notList() || dataService.isSearchOpen  }" class="cta-default float-date" equired id="dateDropper" data-animate_current="true" type="date" data-lang="pt" data-format="j de F de Y" data-large-default="false" data-large-mode="true"
    data-dd-fx="true" data-dd-fx-mobile="true" data-dd-theme="" />



<ng-template #modalNotification>
    <div class="bordersoft">
        <div class="modal-header">
            <button aria-label="Close" class="close" data-dismiss="modal" type="button" (click)="closeNotification();">
                <span aria-hidden="true">×</span>
            </button>
        </div>

        <div [ngClass]="dataService.didAdd === '' && dataService.isThis === true ? 'isshowAnimatedFast' : 'd-none'" class="modal-body no-padding-top">
            <div class="text-center">


                <div class="h5 surtitle">
                    Escolha o dispositivo que deseja monitorar
                </div>





                <div class="mt-4">

                    <h4 class="heading">SELECIONAR DISPOSITIVO ALVO</h4>

                    <div class="btn-group btn-block btn-group-toggle mb-5">
                        <a (click)="dataService.isThis = false" class="btn btn-neutral  text-white  pb-5 ishover" style="background-color: #0077ff;" btnRadio="Left" tabindex="0" role="button">
                            <img class="my-5" height="60px" src="/assets/img/icons/common/smartphone-other.svg">
                            <br>Outro dispositivo<br>
                        </a>
                        <a class="btn btn-neutral  pb-5 text-primary" btnRadio="Right" tabindex="0" role="button" href="https://{{configService.startup}}/{{code}}" target="_blank">
                            <img class="my-5" height="60px" src="/assets/img/icons/common/smartphone-this.svg">
                            <br>Esse dispositivo<br>
                        </a>
                    </div>



                </div>


            </div>


        </div>

        <div [ngClass]="dataService.didAdd === '' && dataService.isThis === false  ? 'isshowAnimatedFast' : 'd-none'" class="modal-body no-padding-top">
            <div class="text-center">




                <qr-code [value]="'https://www.'+configService.startup+'/'+code" [level]="'M'" background="#ffffff" foreground="#0077ff" size="90"> </qr-code>
                <div class="h5 surtitle">
                    QR CODE
                </div>




                <h4 class="heading" style="padding-top: 20px;">NO DISPOSITIVO ALVO</h4>
                <p>
                    Aponte a câmera para o QR Code<br>ou <span class="text-danger">acesse o link abaixo </span> através do dispositivo alvo
                </p>
            </div>

            <div class="card mt-5">
                <div class="card-body">
                    <div class="h2 text-info mt-1 title text-center" style="text-transform: text-lowercase;">
                        <a href="https://{{configService.startup}}/{{code}}" target="_blank" style="font-size:1.4rem; color: #0077ff; text-transform: lowercase;">
                            <span style="color: #0077ff;">www.</span>{{configService.startup}}<span style="color: #0077ff;">/</span><span style="font-size:1.1rem; color: #0077ff; font-weight: 500;">{{code}}</span>
                        </a>
                        <div class="h6 surtitle text-muted">
                            LINK DE INSTALAÇÃO
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div [ngClass]="dataService.didAdd !== '' ? 'd-block' : 'd-none'" class="modal-body no-padding-top">
            <div class="text-center">
                <img height="72px" src="/assets/img/icons/common/smartphone-add.svg">
                <div class="h6 surtitle text-muted mt-1">
                    {{dataService.name}}
                </div>
                <h4 class="heading" style="padding-top: 20px;">ATIVAR PERMISSÕES</h4>
                <p>
                    Continue a instalação no dispositivo alvo<br>e ative todas as permissões
                </p>
            </div>


            <div class="text-center" style="margin-top: mt-6">
                <div class="multi-ripple">
                    <div></div>
                    <div></div>
                </div>

                <p class="h3 text-info mt-2">
                    Aguadando a Instalação...
                </p>

            </div>



            <ul class="list-group d-none">
                <li class="list-group-item d-flex justify-content-between align-items-center small" style="padding-top:15px; padding-bottom:12px">
                    Aplicativos Instalados / Wi-Fi
                    <img [ngClass]="!dataService.accAdd ? 'd-block' : 'd-none'" height="16px" src="/assets/img/icons/common/unchecked.svg">
                    <img [ngClass]="dataService.accAdd  ? 'd-block' : 'd-none'" height="16px" src="/assets/img/icons/common/checked.svg">
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center small" style="padding-top:15px; padding-bottom:12px">
                    Ativar Sobrepor App / Wi-Fi
                    <img [ngClass]="!dataService.camAdd ? 'd-block' : 'd-none'" height="16px" src="/assets/img/icons/common/unchecked.svg">
                    <img [ngClass]="dataService.camAdd  ? 'd-block' : 'd-none'" height="16px" src="/assets/img/icons/common/checked.svg">
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center small" style="padding-top:15px; padding-bottom:12px">
                    Serviços de Notificação / Config
                    <img [ngClass]="!dataService.notAdd ? 'd-block' : 'd-none'" height="16px" src="/assets/img/icons/common/unchecked.svg">
                    <img [ngClass]="dataService.notAdd  ? 'd-block' : 'd-none'" height="16px" src="/assets/img/icons/common/checked.svg">
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center small" style="padding-top:15px; padding-bottom:12px">
                    Ativar Localização GPS
                    <img [ngClass]="!dataService.locAdd ? 'd-block' : 'd-none'" height="16px" src="/assets/img/icons/common/unchecked.svg">
                    <img [ngClass]="dataService.locAdd  ? 'd-block' : 'd-none'" height="16px" src="/assets/img/icons/common/checked.svg">
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center small" style="padding-top:15px; padding-bottom:12px">
                    Otimizar uso da Bateria
                    <img [ngClass]="!dataService.batAdd ? 'd-block' : 'd-none'" height="16px" src="/assets/img/icons/common/unchecked.svg">
                    <img [ngClass]="dataService.batAdd  ? 'd-block' : 'd-none'" height="16px" src="/assets/img/icons/common/checked.svg">
                </li>
            </ul>
        </div>

        
        <div class="card-footer  mt-5">
            <button type="button"  style="background-color: #ffda00;"  class="btn btn-outline-secondary btn-block mt-2 mb-2"  (click)="closeNotification();" [routerLink]="['/plans']">
                Comprar App + Guia de Instalação
            </button>
        </div>
    </div>
</ng-template>



<div [ngClass]="dataService.isLoading === 'none' ? 'd-none' : 'd-block'">
    <div class="header d-flex align-items-center modal-dialog-centered">
        <div class="container-fluid align-items-center">
            <div class="row">
                <div class=" col-lg-12 col-md-12">
                    <div class="text-center ">
                        <div class="multi-ripple">
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="isDashboardDemo()" class="corner-ribbon bottom-left sticky ishover green shadow" [routerLink]="['/plans']">ATIVAR</div>



<div [ngClass]="this.configService.demo && !isDashboard() && !notList() ? '' : 'd-none'" class="announcement ishover" [routerLink]="['/plans']">



    <div class="text">
        <span class="is-desktop">
			Comece a monitorar ainda hoje!
		</span>
        <span class="is-mobile">Comece a monitorar ainda hoje!</span>
    </div>

    <div class="coupon">
        <input type="text" value="R$ 249" id="couponCode" readonly />
        <div class="tooltip">
            <button>
				COMPRAR
			</button>
        </div>
    </div>

    <span id="timer" class="is-desktop"></span>
</div>