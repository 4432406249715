import { Injectable, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Code } from "../services/code";
import { AuthService } from "./auth.service";
import { FormsModule } from '@angular/forms';
import { User } from "../services/user";

import * as moment from 'moment';
import 'moment/locale/pt-br';



@Injectable({
    providedIn: 'root'
})
export class AdminService implements OnDestroy {

  public uid: string;
  public email: string;
  public displayName: string;
  public photoURL: string;
  public emailVerified: boolean;
  public add: boolean;
  public app: string;
  public plan: string;
  public active: boolean;
  public dropsPhotos: number;
  public dropsAudios: number;
  public created: number;
  public expire: number;

  public expireInfo: string;


  public userRef: any;

  public isExpire: boolean;


   
    constructor(
        public afs: AngularFirestore,   // Inject Firestore service
        public authService: AuthService

    ) {



      this.callisUser();

        this.startTimer();

    }

    interval;

startTimer() {
  this.interval = setInterval(() => {
    this.callisUser();
  },60000,60000)
}

callisUser() {
  if (this.authService.uid !== "" && this.authService.uid !== null){
    this.isUser(this.authService.uid);
  }
}

    isUser(uid: string) {

      if (this.userRef) {
        this.userRef.unsubscribe();
    }
  
      this.userRef = this.afs.collection('users').doc(uid).collection('admin').doc('profile').snapshotChanges().subscribe(
        profile => {
            if (profile) {
  
                  this.uid = profile.payload.get("uid");
                  this.email = profile.payload.get("email");
                  this.displayName = profile.payload.get("displayName");
                  this.photoURL = profile.payload.get("photoURL");
                  this.emailVerified = profile.payload.get("emailVerified");
                  this.add = profile.payload.get("add");
                  this.app = profile.payload.get("app");
                  this.plan = profile.payload.get("plan");
                  this.active = profile.payload.get("active");
                  this.dropsPhotos = profile.payload.get("dropsPhotos");
                  this.dropsAudios = profile.payload.get("dropsAudios");
                  this.created = profile.payload.get("created");
                  this.expire = profile.payload.get("expire");

                  this.getExpire(this.expire)
                
            }
        })
  

    } 

    Unsubscribe(){
      if (this.userRef) {
        this.userRef.unsubscribe();
      }
    }

    
    ngOnDestroy() {
      console.log("%c  stop AdminService", 'color: #ff0000');
   }


    getExpire(dateExpire: any) {


      var timeNow = Math.floor(Date.now());
      var timeUnix: number = +dateExpire;
      var timeExpire = new Date(timeUnix);
      var eValidoCurto = moment(timeExpire).calendar();
      var eValidoLongo = moment(timeExpire).format('LLL');
      //var timeNow_menos_2_dias = Math.floor(Date.now() / 1000) - 172800 //<--2 dias 
  


      if (dateExpire > timeNow) {
          if (this.plan == 'Free') {
            this.expireInfo  = "Seu teste expira " + eValidoCurto
          } else {        
            this.expireInfo  = "Seu plano expira " + eValidoCurto
          }
      }
      else {
          this.isExpire = true;
          if (this.plan == 'Free') {
            this.expireInfo  = "Seu teste expirou " + eValidoLongo
          } else {
            this.expireInfo = "Seu plano expirou " + eValidoLongo
          }
      }
  
      console.log(this.expireInfo );
  
      if (dateExpire != null && dateExpire < timeNow) {

        if (this.active == true) {
        this.afs.collection('users').doc(this.authService.uid).collection('admin').doc('profile').update({
          active: false,
        })
      }
          this.isExpire = true;
          console.log("expirou: " + 'true');
      } else {
        this.isExpire = false;
          console.log("nao expirou: " + 'true');
      }
  
      console.log("expire:  " + dateExpire)
      console.log("timeNow: " + timeNow);
      
    }



    

}

