import { Component, OnInit, AfterViewInit, HostListener, ElementRef, OnDestroy } from "@angular/core";
import { DataService } from "../../shared/services/data.service";


@Component({
  selector: "app-admin-layout",
  templateUrl: "./admin-layout.component.html",
  styleUrls: ["./admin-layout.component.scss"]
})
export class AdminLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  isMobileResolution: boolean;
  isBrowser: any;

  constructor(
    public dataService: DataService,

  ) {

      if (window.innerWidth < 1200 ) {
        this.isMobileResolution = true;
      } else {
        this.isMobileResolution = false;
      }


    


  }
  @HostListener("window:resize", ["$event"])
  isMobile(event) {

    if (window.innerWidth < 1200 ) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  
  }
  ngOnInit() {
  }
  ngAfterViewInit() {
    this.dataService.start();
    console.log("%c  login", 'color: #2bffc6');
}

ngOnDestroy() {
    console.log("%c  logout", 'color: #ff0000');
}
}
