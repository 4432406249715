<nav class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white" id="sidenav-main"
    (mouseover)="onMouseEnterSidenav()" (mouseout)="onMouseLeaveSidenav()">
    <perfect-scrollbar>
        <div class="scrollbar-inner">
            <div class="sidenav-header d-flex align-items-center">
                <a class="navbar-brand" [routerLink]="['/dashboard']">
                    <img src="{{configService.applogo}}" class="navbar-brand-img" height="32px" alt="..." />
                </a>
                <div class="ml-auto">
                    <!-- Sidenav toggler -->
                    <div class="sidenav-toggler d-none d-xl-block" data-action="sidenav-unpin"
                        data-target="#sidenav-main" (click)="minimizeSidebar()">
                        <div class="sidenav-toggler-inner">
                            <i class="sidenav-toggler-line"></i>
                            <i class="sidenav-toggler-line"></i>
                            <i class="sidenav-toggler-line"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="navbar-inner">
                <div class="collapse navbar-collapse" id="sidenav-collapse-main">
                    <!-- Collapse header -->
                    <ul class="navbar-nav">
                        <li *ngFor="let menuitem of menuItems; let i = index" (click)="nav=i" id="nav{{i}}"
                            [ngClass]="{'color-transparent': nav!==i}" class="nav-item {{ menuitem.color }} ">
                            <!--If is a single link-->

                            <a routerLinkActive="active" [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'"
                                class="nav-link">

                                <div class="d-flex justify-content-between">

                                    <div class="d-flex float-left" style="width: 160px;">
                                        <div style="width:28px">

                                            <img height="20px" src="{{ menuitem.icontype }}">
                                            
                                        </div>
                                        <span class="nav-link-text ml-3" style="padding-top: 0.05rem;">{{ menuitem.title }}</span>
                                    </div>

                                  

                                    <div *ngIf="menuitem.title === 'WhatsApp' && dataService.audiosCount > 0"
                                        class="nav-link-text text-right">
                                        <div style="width: 40px;">
                                            <small class="time"><span class="text-success">●</span>
                                                {{ dataService.audiosCount }}</small>
                                        </div>
                                    </div>
                                    <div *ngIf="menuitem.title === 'Chamadas' && dataService.callsCount > 0"
                                        class="nav-link-text text-right">
                                        <div style="width: 40px;">
                                            <small class="time"> {{ dataService.callsCount }}</small><span
                                                class="text-success">●</span>
                                        </div>
                                    </div>
                                    <div *ngIf="menuitem.title === 'Contatos' && dataService.contactsCount > 0"
                                        class="nav-link-text text-right">
                                        <div style="width: 40px;">
                                            <small class="time"> {{ dataService.contactsCount }}</small><span
                                                class="text-success">●</span>
                                        </div>
                                    </div>
                                    <div *ngIf="menuitem.title === 'Facebook' && dataService.facebookCount > 0"
                                        class="nav-link-text text-right">
                                        <div style="width: 40px;">
                                            <small class="time"> {{ dataService.facebookCount }}</small><span
                                                class="text-success">●</span>
                                        </div>
                                    </div>
                                    <div *ngIf="menuitem.title === 'Instagram' && dataService.instagramCount > 0"
                                        class="nav-link-text text-right">
                                        <div style="width: 40px;">
                                            <small class="time"> {{ dataService.instagramCount }}</small><span
                                                class="text-success">●</span>
                                        </div>
                                    </div>
                                    <div *ngIf="menuitem.title === 'Timeline' && dataService.timelineCount > 0"
                                        class="nav-link-text text-right">
                                        <div style="width: 40px;">
                                            <small class="time"> {{ dataService.timelineCount }}</small><span
                                                class="text-success">●</span>
                                        </div>
                                    </div>
                                    <div *ngIf="menuitem.title === 'Keys' && dataService.keysCount > 0"
                                        class="nav-link-text text-right">
                                        <div style="width: 40px;">
                                            <small class="time"> {{ dataService.keysCount }}</small><span
                                                class="text-success">●</span>
                                        </div>
                                    </div>
                                    <div *ngIf="menuitem.title === 'Photos' && dataService.photosCount > 0"
                                        class="nav-link-text text-right">
                                        <div style="width: 40px;">
                                            <small class="time"> {{ dataService.photosCount }}</small><span
                                                class="text-success">●</span>
                                        </div>
                                    </div>
                                    <div *ngIf="menuitem.title === 'Pesquisas' && dataService.searchesCount > 0"
                                        class="nav-link-text text-right">
                                        <div style="width: 40px;">
                                            <small class="time"> {{ dataService.searchesCount }}</small><span
                                                class="text-success">●</span>
                                        </div>
                                    </div>
                                    <div *ngIf="menuitem.title === 'SMS' && dataService.smsCount > 0"
                                        class="nav-link-text text-right">
                                        <div style="width: 40px;">
                                            <small class="time"> {{ dataService.smsCount }}</small><span
                                                class="text-success">●</span>
                                        </div>
                                    </div>
                                    <div *ngIf="menuitem.title === 'Sites' && dataService.websitesCount > 0"
                                        class="nav-link-text text-right">
                                        <small class="time"> {{ dataService.websitesCount }}</small><span
                                            class="text-success">●</span>
                                    </div>
                                    <div *ngIf="menuitem.title === 'WhatsApp' && dataService.whatsappCount > 0"
                                        class="nav-link-text text-right">
                                        <div style="width: 40px;">
                                            <small class="time"> {{ dataService.whatsappCount }}</small><span
                                                class="text-success">●</span>
                                        </div>

                                    </div>



                                </div>




                            </a>
                            <!--If it have a submenu-->
                            <a data-toggle="collapse" routerLinkActive="active" *ngIf="menuitem.type === 'sub'"
                                (click)="menuitem.isCollapsed = !menuitem.isCollapsed"
                                [attr.aria-expanded]="!menuitem.isCollapsed" [attr.aria-controls]="menuitem.collapse"
                                class="nav-link ishover mr-2">
                                <div style="width:28px">
                                    <img height="20px" src="{{ menuitem.icontype }}">
                                </div>                                <span class="nav-link-text ml-3" style="padding-top: 0.05rem;">{{ menuitem.title }}</span>
                            </a>

                            <!--Display the submenu items-->
                            <div id="{{ menuitem.collapse }}" class="collapse" *ngIf="menuitem.type === 'sub'"
                                [collapse]="menuitem.isCollapsed" [isAnimated]="true">
                                <ul class="nav nav-sm flex-column">
                                    <li *ngFor="let childitems of menuitem.children" class="nav-item">
                                        <!--If is a single link-->
                                        <a routerLinkActive="active" [routerLink]="[menuitem.path, childitems.path]"
                                            class="nav-link ml-3" style="padding-top: 0.05rem;" *ngIf="childitems.type === 'link'">
                                            {{ childitems.title }}
                                        </a>
                                        <!--If it have a submenu-->
                                        <a data-toggle="collapse"
                                            (click)="childitems.isCollapsed = !childitems.isCollapsed"
                                            [attr.aria-expanded]="!childitems.isCollapsed"
                                            [attr.aria-controls]="childitems.collapse" *ngIf="childitems.type === 'sub'"
                                            class="nav-link">
                                            {{ childitems.title }}
                                        </a>
                                        <!--Display the submenu items-->
                                        <div id="{{ childitems.collapse }}" class="collapse"
                                            *ngIf="childitems.type === 'sub'" [collapse]="childitems.isCollapsed"
                                            [isAnimated]="true">
                                            <ul class="nav">
                                                <li *ngFor="let childitem of childitems.children" class="nav-item">
                                                    <a href="javascript:void(0)" class="nav-link">
                                                        {{ childitem.title }}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                    <hr class="my-3" />



                <div class="">
                    <!-- Divider -->
    


                    <ul class="navbar-nav mb-md-3 bottom">
                        <li [ngClass]="{'color-transparent': !isPlans()}" class="nav-item color-plans">
                            <a class="nav-link" routerLinkActive="active" [routerLink]="['/plans']">
                                <img height="17px" src="/assets/img/icons/common/crown.svg">
                                <span class="nav-link-text ml-4" style="padding-top: 0.05rem;" style="color: #FF9700; text-shadow: 1px 1px #d8deea6c;">Versão PRO</span>
                            </a>
                        </li>


                        <li [ngClass]="{'color-transparent': !isSettings()}" class="nav-item color-settings">
                            <a class="nav-link" routerLinkActive="active" [routerLink]="['/settings']">
                                <img height="17px" src="/assets/img/icons/common/switch.svg">
                                <span class="nav-link-text ml-4" style="padding-top: 0.05rem;">Opções</span>
                            </a>
                        </li>




                        <li class="nav-item color-default">
                            <a class="nav-link ishover" (click)="SignOut()">
                                <img height="17px" src="/assets/img/icons/common/power-button.svg">
                                <span class="nav-link-text ml-4" style="padding-top: 0.05rem;">Sair</span>
                            </a>
                        </li>
                    </ul>

                </div>
                </div>
            </div>
        </div>
    </perfect-scrollbar>

</nav>